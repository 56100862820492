.App {
  text-align: center;
}

footer {
  background: hsla(0, 0%, 0%, .7);
  min-height: 15vh;
}

footer a{
  color: #FFF;
  font-size: xx-large;
}

footer a:hover{
  color: #FFF;
  text-decoration: none;
  text-shadow: #14e2dd 5px 5px 3px;
  font-size: xx-large;
  font-weight: 600;
}

header h2, footer h2 {
  -webkit-text-stroke: 1px black;
  font-variant: small-caps;
}
header p {
  -webkit-text-stroke: 0.1px black;
}

header {
  min-height: 44vh;
}
nav {
  visibility: hidden;
  position: sticky;
  background: hsla(0, 0%, 100%, .7);
  top: 0;
  font-variant: small-caps;
  z-index: 3;
}

#page-navigation {
  display: none;
}

nav img {
  height: 30px;
  margin: 0 10px 0 10px;
}


.App-header {
  background-color: #282c34;
  opacity: 0.7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.content {
  border: #14e2dd solid 1px;
  align-items: center;
  position: relative;
  padding: 2vw;
  min-height: 10vh;
  color: #FFF;
  text-decoration: stroke;
}
.content h1{
  background-color: #14e2dd;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: x-large;
  word-break:normal;
  color: #000;
}
.content h3{
  align-self: center;
  font-size: large;
  word-break:normal;
  font-variant: small-caps;
  text-align: center;
}


.gallery {
  position: relative;
  padding: 2vw;
  min-width: 70vw;
  min-height: 40vh;
  background: hsla(0, 0%, 0%, .6);
}
.gallery::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 0%, .5);
  z-index: -1;
}

.notpreface {
  position: relative;
  margin: 0;
  padding: 2vw;
  max-width: 100vw;
  min-width: 80vw;
  min-height: 40vh;
  vertical-align: middle;
  background: hsla(0, 0%, 100%, .6);
}

.notpreface h1 {
  font-weight: 600;
  margin-left: 55px;
  margin-right: 55px;
  font-variant: small-caps;
  display: inline-block;
}

.preface {
  
  grid-gap: 10px;
  position: relative;
  margin: 0;
  padding: 2vw;
  max-width: 100vw;
  min-width: 80vw;
  min-height: 50vh;
  vertical-align: middle;
  text-align: center;
  background: hsla(0, 0%, 0%, .3);
}
.preface::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 0%, .3);
  z-index: -1;
}

.preface img, .notpreface img {
  margin-bottom: 2vh;
  width: 200px;
}

.preface a , .preface a:hover{
  text-decoration: none;
  color: inherit;
  
  
}

.preface p {
  margin-left: 3vw;
  width: 30vw;
  text-align: center;
  color: #FFF;
}

.preface div:hover img{
  transform: scale(1.3);
}

.preface div{
	position: absolute;
	top: 0%;
  left: 25%;
	transform: translate(25%, 0%);
	padding: 10px;
	width: 30vw;
	height: 30vh;
  margin-top: 4%;
  cursor: pointer;
  font-weight: 500;
  font-size: x-large;
  text-align: center;
  font-variant: small-caps;
}

.description h3 {
  margin-bottom: 3vh;
}

.content h1, .preface h1, .gallery h1 {
  font-variant: small-caps;
  margin: 0 0 5vh 0;
  text-align: center;
}

.picture {
  position: absolute;
  background-color: hsla(0, 0%, 100%, .9);
  left: -10%;
  top: 0%;
  width: 13vw;
  overflow: hidden;
  box-shadow: -2px -2px 4px hsla(0, 0%, 100%, .9);
}

.picture img {
  max-width: 100%;
}

.description {
  text-align: justify;
  font-size: 120%;
}

.footer {
  display: grid;
  grid-template-columns: 20% 45% 32%;
  grid-gap: 10px;
  padding-top: 4vh;
  min-height: 120px;
  position: relative;
  color: #FFF;
  border: #14e2dd solid 0px;
}

footer div p{
  font-variant: small-caps;
  font-size: x-large;
}

#main {
  display: block;
  grid-gap: 1%;
  padding: 1%;
  position: relative;
}

#finish {
  display: block;
  grid-gap: 1vw;
  padding: 1vw;
  position: relative;
}

.footer-contact {
  background-image: url('/public/lc_ico/contact_ico.svg');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 30%;
  background-size: 4vw;
  margin: 0 15px;
}

.social-ico a:active{
	color: #FFF;
	transform: scale(0.8);
	text-decoration: none;
}

.social-ico p{
  display: inline;
  margin: 0 15px;
}


.social-ico img {
	height: 40px;
	width: 40px;
	margin-bottom: 10px;
}


.footer-logo img:nth-child(1){
	height: 70px;
  margin: 0 30px;
}

.footer-logo img:nth-child(2){  
  display: inline;
  height: 12px;
  filter: invert();
  font-variant: small-caps;
}

div .thumbnail
{
  min-height: 90vh;
	align-content: right;
  margin: 10vh 0;
}

.miniature {
  width: 20vw;
  height: 10vw;
  margin: 15px;
  display: inline-grid;
  border:#14e2dd solid 2px;  
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  animation: pageIn 1s 3s forwards;
}
.miniature-logo {
  width: 50vw;
  margin: 5px;
  display: inline-grid;
  animation: pageIn 1s forwards;

}
.miniature-card {
  height: 25vh;
  margin: 5px;
  display: inline-grid;
  animation: pageIn 1s forwards;

}

.miniature:hover {
  width: 20vw;
  height: 10vw;
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.9);
}

.miniature:active {
  width: 20vw;
  height: 10vw;
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  opacity: 1;
  transition: 1s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
}

@-webkit-keyframes rotateIn{
  from {
    transform: rotate(45deg);
    width: 20vw;
    height: 10vw;
  }
  to { 
    transform: rotate(0deg);
    width: 20vw; 
    height: 10vw;}
}

@keyframes rotateIn{
  from {
    transform: rotate(45deg);
    width: 20vw;
    height: 10vw;
  }
  to { 
    transform: rotate(0deg);
    width: 20vw; 
    height: 10vw;}
}


.gallery {
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 2.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@-webkit-keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-webkit-keyframes slideshowlb{
  0% {
    opacity: 0;
    visibility: hidden;
  }
 23% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
  25% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  48% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  50% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
 100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideshowlb{
  0% {
    opacity: 0;
    visibility: hidden;
  }
 23% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
  25% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  48% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  50% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
 100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes slideshowjp{
  0% {
    opacity: 0;
    visibility: hidden;
  }
 1% {                      /* = fadingin / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  23% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  25% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
 100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideshowjp{
  0% {
    opacity: 0;
    visibility: hidden;
  }
 1% {                      /* = fadingin / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  23% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  25% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
 100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes slideshowjl{
  0% {
    opacity: 0;
    visibility: hidden;
  }
 74% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
  76% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  98% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
 100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideshowjl{
  0% {
    opacity: 0;
    visibility: hidden;
  }
 74% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
  76% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  98% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
 100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes slideshowkl{
  0% {
    opacity: 0;
    visibility: hidden;
  }
 49% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
 51% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  73% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  75% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
 100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideshowkl{
  0% {
    opacity: 0;
    visibility: hidden;
  }
 49% {                      /* = fadingin / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
 51% {                     /* = (fadingin + showing) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  73% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 1;
    visibility: visible;
  }
  75% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
    opacity: 0;
    visibility: hidden;
  }
 100% {
    opacity: 0;
    visibility: hidden;
  }
}
#jp, #jl, #kl, #lb {


}

#jplogo, #jllogo, #kllogo, #lblogo {


}

#jpcard, #jlcard, #klcard, #lbcard {


}

.about-us{
  display: block;
  position: absolute;
  margin: 0;
  padding: 2vw;
  max-width: 97%;
  min-width: 97%;
}


#jlcard{
  opacity: 0;
  display: none;
}
#jpcard{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#lbcard{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#klcard{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( -27deg );
}
#jllogo{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( 27deg );

}
#jplogo{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( 27deg );
}
#lblogo{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( 27deg );
}
#kllogo{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transform: perspective( 800px ) rotateY( 27deg );
}
#jl{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
}
#jp{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#lb{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#kl{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

#about3{
  opacity: 0;
  animation-name: slideshowjl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
}
#about0{
  opacity: 0;
  animation-name: slideshowjp;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#about1{
  opacity: 0;
  animation-name: slideshowlb;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
#about2{
  opacity: 0;
  animation-name: slideshowkl;
  animation-duration: 24s;
  animation-delay:1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
@media only screen and (max-width: 600px) {
  .description {
    margin: 5vh 1vw 5vh 1vw;
    text-align: justify;
    font-size: 100%;
  }

  header {
    min-height: 15px;
  }

  .content {
    margin: 5vh 10vw 5vh 10vw ;
    min-width: 80vw;
  }

  .footer, .preface {
    display: block;
  }
}
.copyright-logo {
  margin-left: 2vw;
  max-width: 66%;
}

.logo-full {
  margin: 7vh;
  max-height: 25vh;
  animation: logo-delay 0.1s forwards;
  animation-delay: 3.8s;
  border-radius: 15px 40px 15px 40px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
}

@keyframes logo-delay{
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {                 
    opacity: 1;
    visibility: visible;
  }

}

.logotype-nav {
  margin: 10px;
  max-height: 10px;
}

.logotype {
  margin: 5vw;
  max-width: 80vw;
  filter: invert();
}


.lang-bar img {
  height: 33px;
  cursor: pointer;
  margin: 10px;
}
#en {
  margin: 10px;
  transform: perspective(100px) rotateY(-35deg);
}
#no {
  height: 150%;
  margin: 10px;
}
#pl {
  margin: 10px;
  transform: perspective(100px) rotateY(35deg);
}

#no:hover, #en:hover, #pl:hover {
    transform: scale(1.2);
}

#about, .preface {
  color: #FFF;
  background:none;
  position: relative;
  box-shadow:
  inset 0 0 50px #000,
  inset 20px 0 80px #14e2dd,
  inset -20px 0 80px #0ff,
  inset 20px 0 300px #14e2dd,
  inset -20px 0 300px #0ff,
  0 0 50px #000,
  -10px 0 80px #14e2dd,
  10px 0 80px #0ff;
}