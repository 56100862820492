#lc-header{ 
  background-color: #000;
    padding: 1vh;
    width: 90vw;
    height: 90vh;
    border: #14e2dd solid 1px;
    box-shadow: 2px 2px 4px hsla(0, 0%, 100%, .9);
    position: fixed;
    top: 90px;
    left: 2vw;
    z-index: 90; 
    height: fit-content;
  }
  
  #elsa {
    width: 120px;
    height: 180px;
    margin: 0 0;
    overflow: hidden;
    display:block;
    background-image: url('./elsa_mini.png');
    background-repeat: no-repeat;
    background-size: 95%;
    background-position-x: 30%;
    background-position-y: 60%;
  }
  
  #elsa video {
    border: #14e2dd solid 1px;
    box-shadow: 2px 2px 4px hsla(0, 0%, 100%, .9);
    width: 120px;
    height: 180px;
    transform: scale(1);
    position: absolute;
    top: 2%;
    left: 1%;
  }

  #elsa-scripted {
    width: 100%;
    min-height: 380px;
    height: fit-content;
    background-color: #000;
    color: #14e2dd;
    font-size: 110%;
    padding: 10% 0 0px 0 ;
    text-align: left;
    font-family: 'Michroma';
  }

  #elsa-scripted p{
    cursor: default;
    width: 96%;
    text-align: left;
    font-size: 1rem;
    line-height: 2rem;
    margin: 0 2% 5% 2%;

  }

  #elsa-continue {
    font-size: 0.8rem;
    cursor: pointer;
    width: 90%;
    height: 2rem;
    border: #14e2dd 1px solid;
    padding: 0.2rem 1rem;
    margin: 0.4rem 1rem;
  }
  #elsa-continue p {
    cursor: pointer;
  }

  #elsa-continue:hover {
    border: #14e2dd 2px solid;


  }

  #elsa-popup {
    cursor: pointer;
    position: fixed;
    top: 150px;
    left: 50px;
    width: 5rem;
    height: 5rem;
    margin: 0 0;
    background-color: #14e2dd;
    z-index: 11;
    opacity: 1;
    text-align: left;
    border: #000 0px solid;
    border-radius: 100%;
  }

  #elsa-close-button {
    position: absolute;
    cursor: pointer;
    top: 1rem;
    right: 1rem;
    font-size: 20px;
    font-weight: 500;
    color: #14e2dd;
    text-shadow: #FFF 2px 2px 2px;
    z-index: 3;
    visibility: visible;
  }

  #elsa-close-button:hover {
    font-weight: 600;
    color: #FFF;
    text-shadow: #14e2dd 2px 2px 2px;
  }

  #elsa-miniature {
    background-image: url('./elsa_mini.png');
    background-position: 0% 30%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 2%;
    width: 96%;
    height: 96%;
    z-index: 3;
    border-radius: 100%;
  }

  .show {
    opacity: 0;
    animation: show 0.2s 0.3s ease-out forwards;
    z-index: 0;
  }

  .hide {
    opacity: 1;
    animation: hide 0.2s 0.2s ease-out forwards;
    z-index: 3;
  }
  
  @-webkit-keyframes show{
    from { 
      opacity: 0;
      z-index: 0;
     }
    to
      { 
      opacity: 1;
      z-index: 3;

     }
}

@keyframes show{
  from { 
    opacity: 0;
    z-index: 0;

   }
  to
    { 
      opacity: 1;
    z-index: 3;

   }
}

@-webkit-keyframes hide{
  from { 
    opacity: 1;
    z-index: 3;

   }
  to
    { 
      opacity: 0;
      z-index: 0;

   }
}

@keyframes hide{
  from { 
    opacity: 1;
    z-index: 3;

   }
  to
    { 
      opacity: 0;
      z-index: 0;

   }
}